#main-carousel .swiper-button-prev {
    padding: 30px !important;
    background-color: #000 !important;
    opacity: 0.8;
    color: #fff !important;
}

#main-carousel .swiper-button-next {
    padding: 30px !important;
    background-color: #000 !important;
    opacity: 0.8;
    color: #fff !important;
}

@media (max-width: 420px) {
    #main-carousel .swiper-button-prev {
        display: none;
    }
    
    #main-carousel .swiper-button-next {
        display: none;
    }
}
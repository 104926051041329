#ig-posts-carousel-lg .swiper-button-prev {
    padding: 30px !important;
    background-color: #000 !important;
    opacity: 0.8;
    color: #fff !important;
}

#ig-posts-carousel-lg .swiper-button-next {
    padding: 30px !important;
    background-color: #000 !important;
    opacity: 0.8;
    color: #fff !important;
}
#shop-now-carousel .swiper-slide > div {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#shop-now-carousel .swiper-button-prev {
    padding: 20px !important;
    background-color: #000 !important;
    color: #fff !important;
}

#shop-now-carousel .swiper-button-next {
    padding: 20px !important;
    background-color: #000 !important;
    color: #fff !important;
}

@media (max-width: 380px) {
    #shop-now-carousel .swiper-button-prev {
        display: none;
    }
    
    #shop-now-carousel .swiper-button-next {
        display: none;
    }
}
#products-carousel .swiper-button-prev,
#products-carousel-lg .swiper-button-prev {
    color: #fff !important;
    font-weight: 900;
}

#products-carousel .swiper-button-next,
#products-carousel-lg .swiper-button-next {
    color: #fff !important;
    font-weight: 900;
}
#cocktails-carousel .swiper-button-prev {
    padding: 30px !important;
    background-color: #000 !important;
    opacity: 0.8;
    color: #fff !important;
}

#cocktails-carousel .swiper-button-next {
    padding: 30px !important;
    background-color: #000 !important;
    opacity: 0.8;
    color: #fff !important;
}

@media (max-width: 380px) {
    #cocktails-carousel .swiper-button-prev {
        display: none;
    }
    
    #cocktails-carousel .swiper-button-next {
        display: none;
    }
}
.card {
    transition: box-shadow .3s;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
    
}

.card:hover {
    box-shadow: 0px 3px 8px 3px rgba(0, 0, 0, 0.15), 0px 3px 6px 0px rgba(0, 0, 0, 0.30);
}

.carousel-item {
    background-color: #fff;
    margin-top: 0.5em;
    width: 100%;
    height: 100%;
  
    .carousel-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
  
      img {
        width: 50vh;
        height: 50vh;
        border: 0.5px solid #9a9a9a;
        border-radius: 50%;
      }
  
      span {
        width: 50%;
      }
    }
  }

.descarga-app img{
  src: url('../assets/nimg/rd/como/Descargalaappgris.svg');
}

.descarga-app:hover img{
  src: ('../assets/nimg/rd/como/Descargalaapprojo.svg');
}

.create-your-account{
  background: url('../assets/nimg/rd/como/Creatucuentagris.svg');
}

.create-your-account:hover{
  background: url('../assets/nimg/rd/como/Creatucuentarojo.svg');
}
.descarga {
    background: url("../assets/nimg/rd/como/Descargalaappgris.svg") no-repeat center;
    height: 400px !important;
}

.descarga:hover {
    background: url("../assets/nimg/rd/como/Descargalaapprojo.svg") no-repeat center;
}

.perfil {
    background: url("../assets/nimg/rd/como/Creatucuentagris.svg") no-repeat center;
}

.perfil:hover {
    background: url("../assets/nimg/rd/como/Creatucuentarojo.svg") no-repeat center;
}

.botella {
    background: url("../assets/nimg/rd/como/Eligetubebidafavoritablanco.svg") no-repeat center;
}

.botella:hover {
    background: url("../assets/nimg/rd/como/Eligetubebidafavoritarojo.svg") no-repeat center;
}

.efectivo {
    background: url("../assets/nimg/rd/como/PAGAENTARJETAOEFECTIVOGRIS.svg") no-repeat center;
}

.efectivo:hover {
    background: url("../assets/nimg/rd/como/PAGAENTARJETAOEFECTIVOROJO.svg") no-repeat center;
}